$palette: (
    // primary (cobalt)
    primary-5:  #F2F7FF,
    primary-10: #B9D4FF,
    primary-15: #81B1FF,
    primary:    #0F6AFF,
    primary-20: #094FC2,
    primary-30: #043586,
    primary-40: #011D49,

    // success (emerald)
    success-5:  #F2FFF8,
    success-10: #A3E1BF,
    success-15: #60C38D,
    success:    #058840,
    success-20: #036931,
    success-30: #014A22,
    success-40: #002C14,

    // danger (chili)
    danger-5:  #FFF2F4,
    danger-10: #FAB2BC,
    danger-15: #F47586,
    danger:    #EA0220,
    danger-20: #B30118,
    danger-30: #7B0111,
    danger-40: #440009,

    // warning (apricot)
    warning-5:  #FFF8F2,
    warning-10: #FCD3B4,
    warning-15: #FAAF77,
    warning:    #F46A00,
    warning-20: #BA5100,
    warning-30: #803800,
    warning-40: #471F00,

    // Aditional
    // a1 (mulberry)
    a1-5:  #FFF2FC,
    a1-10: #E7B2DB,
    a1-15: #D079BC,
    a1:    #B84A9F,
    a1-20: #96307F,
    a1-30: #510D42,
    a1-40: #2F0425,

    // a2 (amethist)
    a2-5:  #F7F2FF,
    a2-10: #D2BDF5,
    a2-15: #B08CEA,
    a2:    #8F5EE0,
    a2-20: #6B3DB6,
    a2-30: #2F1061,
    a2-40: #180537,

    // a3 (azure)
    a3-5:  #F2FBFF,
    a3-10: #9AD4F4,
    a3-15: #4AB0E8,
    a3:    #008FDD,
    a3-20: #0074B3,
    a3-30: #003E60,
    a3-40: #002336,

    //  a4 (jade)
    a4-5:  #F2FFFE,
    a4-10: #90E1DA,
    a4-15: #40C4B8,
    a4:    #02A698,
    a4-20: #01877C,
    a4-30: #004044,
    a4-40: #002B28,

    // a5 (turquoise)
    a5-5:  #F2FDFF,
    a5-10: #96E1ED,
    a5-15: #45C6DA,
    a5:    #00ACC8,
    a5-20: #008CA3,
    a5-30: #004C58,
    a5-40: #002B32,

    // a6 (lime)
    a6-5:  #F2FFF2,
    a6-10: #AEE3AE,
    a6-15: #74C674,
    a6:    #44AA44,
    a6-20: #2C8B2C,
    a6-30: #0C4C0C,
    a6-40: #042C04,

    // a7 (peach)
    a7-5:  #FFF3F2,
    a7-10: #FCBFBD,
    a7-15: #F88C89,
    a7:    #F55B56,
    a7-20: #C73C38,
    a7-30: #6A120F,
    a7-40: #3B0604,

    // a8 (lemon)
    a8-5:  #FFFEF2,
    a8-10: #FFF4A2,
    a8-15: #FFEA51,
    a8:    #FFE000,
    a8-20: #9E8B00,
    a8-30: #6E6100,
    a8-40: #3D3600
);

/*
Shade opacities map
Keys of shade color map must not be changed only the value can be adjusted here
*/
$shades: (
    shade-5: (var(--shade), 0.05),
    shade-10: (var(--shade), 0.1),
    shade-20: (var(--shade), 0.2),
    shade-30: (var(--shade), 0.3),
    shade-40: (var(--shade), 0.4),
    shade-50: (var(--shade), 0.5),
    shade-60: (var(--shade), 0.6),
    shade-70: (var(--shade), 0.7),
    shade-80: (var(--shade), 0.8),
    shade-90: (var(--shade), 0.9)
);

/*
Elevations map
Keys of elevations map must not be altered, these will result in variables and classes used across the entire project
eg: --elevation-1, --elevation-0, .elevation-2 etc
Values of elevations map can be altered following these rules:
    - all elevations bellow elevation-1 must use 'dark' color as base (with some level of opacity)
    - all elevations after elevation-1 must use 'light' color as base (with some level of opacity)
    - elevation-1 must be 0% opacity, leaving the base elevation color exposed without alteration
*/
$elevations: (
    elevation-0: (var(--dark), 0.05),
    elevation-1: (var(--light), 0),
    elevation-2: (var(--light), 0.05)
);

// Main themeable colors, usualy these are the only ones which should change
$main: (
    /*
    Theme-independent colors - no mather the theme's type (light / dark), these colors remain the same.
    'dark' will always reffer to a color from a dark spectrum, and 'light' from a light one
    'brand' will point to the manufacturer brand color
    */
    light: #ffffff,
    dark: #000000,
    brand: #00A73C,

    /*
    Shade - main color used in generating neutral colors, usualy used for backgrounds, texts, icons
    On a light theme it should be a dark color, and on a dark theme it should be a light color
    */
    shade: #000000,

    /*
    Elevation - base color for all elevations
    All elevations in Helios are composed of the base elevation
    on top of which another lighter or darker layer with much lower opacity is placed, thus creating depth
    */
    elevation: #ffffff,
);
